:root {
  --main-bg-color: brown;
}



.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}
  
.f20 {
  font-size: 20px;
}  

.f12 {
  font-size: 12px;
}  

.f50 {
  font-size: 50px;
  margin: 0px;
}  
  

.f35 {
  font-size: 35px;
}  
  
  .App-header {
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .menu ul {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
  }
  
  .menu li {
    margin-right: 10px;
  }
  
  .menu li a {
    color: #fff;
    text-decoration: none;
  }
  
  .menu li a:hover {
    text-decoration: underline;
  }



  .btn {
    width: 15rem;
    height: 4rem;
    border-radius: 1rem;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
    background-color: white;
    border-color: #333;
  }
  .btn__primary {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .btn:hover {
    background-color: #e7e6e6;
    transition: background-color 0.2s ease;
    -webkit-tap-highlight-color: transparent;
  }

  .fa-icon-right {
    margin-right: 6px;
  }

  .fa-icon-left {
    margin-left: 6px;
  }


  .menu-right {
    position: absolute;
    right: 25px;
    font-size: 30px;
    color: #9b9b9b;
  }


  .menu-right svg {
    margin-left: 15px;
  }

  .menu-right svg:hover {
    cursor: pointer;
    color:#353535
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .popover {
    position: absolute;
    top: 50%;
    left: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
    font-size: 14px;
    color: #333;
    font-size: 16px;
    animation: fadeIn 0.2s ease-in;
  }

