.QuizComponent {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .QuizComponent-header {
    /* background-color: #f2f2f2; */
    /* padding: 20px; */
  }

  .QuizComponent h2 {
    margin-bottom: 20px;
  }

  .QuizComponent h3 {
    margin-bottom: 10px;
  }

  .QuizComponent form {
    margin-bottom: 30px;
  }

  .QuizComponent input[type="checkbox"] {
    /* margin-right: 10px; */
  }

  .QuizComponent label {
    display: block;
    margin-bottom: 5px;
  }

  .QuizComponent button {
    margin-right: 10px;
  }

  .QuizComponent p {
    margin-bottom: 10px;
  }

  .QuizComponent p:last-child {
    margin-bottom: 0;
  }

  .QuizComponent span {
    margin-left: 5px;
  }


  .quiz-buttons {
    display: flex;
    gap: 10px;
    padding: 5px;
  }

  .quiz-buttons button {
    flex: 1;
  }

  .selectMulti{
    border: 2px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
  }

  .selectMulti:hover{
    border: 2px solid #aaa;
    background-color: #e7e6e6;
    transition: background-color 0.2s ease;
    -webkit-tap-highlight-color: transparent;
  }

  .selectMulti input[type="checkbox"] {
    margin-right: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .selectMulti label {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
  }


  .selectMulti.correct {
    background-color: #a5f5a5; /* Light green */
    transition: background-color 0.5s ease;
  }
  
  .selectMulti.incorrect {
    background-color: #ff9999; /* Light red */
    transition: background-color 0.5s ease;
  }

  .span-result {
    margin-right: 2px;
    font-weight: bold;
  }

  .center-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* STYLE G */

.checkbox {
  display: inline-block;
  position: relative;
  left: 8px;
  padding-right: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkbox__checkmark {
  background-color: #fff;
}
.checkbox input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  left: 8px;
}
.checkbox input:checked ~ .checkbox__body {
  font-weight: bold;
}
.checkbox:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox:hover input:checked ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox .checkbox__checkmark {
  position: absolute;
  top: 1px;
  /* right: 0; */
  height: 22px;
  width: 22px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox .checkbox__checkmark:after {
  content: "✗";
  /* content: "✔"; */
  /* content: ""; */
  position: absolute;
  /* left: -20px; */
  /* left: -20px; */
  /* top: -8px; */
  width: 15px;
  height: 25px;
  /* border: solid #FF9933; */
  /* border-width: 0 4px 4px 0; */
  /* transform: rotate(45deg); */
  transform: scale(1.8);
  opacity: 0;
  transition: opacity 0.25s ease, left 0.25s ease;

}
.checkbox .checkbox__body {
  position: relative;
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  left: 40px;
  transition: font-weight 0.25s ease;
}


.quiz-title {
  padding: 12px;
}


.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar__fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
}